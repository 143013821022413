.message-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 46px;
  min-width: 105px;
  padding: 8px 12px;
  margin: 10px 0;
  border-radius: 4px;
}

.message-text {
  font-size: 12px;
}